// NFTPayButton.js
import React from 'react';

const NFTPayButton = () => {
  const handleClick = () => {
    // Define any additional logic or actions when the button is clicked
    show_creatify_popup();
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://payments.nftpay.xyz/css/iframe_inject.css"
      />
      <script
        id="creatify_script"
        src="https://payments.nftpay.xyz/libs/iframe_inject.js?contract_uuid=c75a03f6-55e3-478f-88b2-1e57f0373e73"
      ></script>

      <button
        style={{
          padding: '10px',
          backgroundColor: 'rgba(11, 234, 74, 0.5)',  // Semi-transparent red background
          color: 'gold',  // Gold-colored text
          border: '2px solid gold',  // Red border
          borderRadius: '10px',  // Rounded corners
          cursor: 'pointer',
          textTransform: 'uppercase',  // All-capital text
          fontWeight: 'bold',  // Bold font
        }}
        onClick={handleClick}
      >
        Buy with card{' '}
        <span id="price-c75a03f6-55e3-478f-88b2-1e57f0373e73">
          <img
            className="nftpay_spinner"
            src="https://payments.nftpay.xyz/img/icons/spinner2.svg"
            alt="Loading Spinner"
          />
        </span>
      </button>
    </div>
  );
};

export default NFTPayButton;
